.app-container {
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  &.blurred {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: fixed;
    width: 100vw;

  }

  .background-blur {
    position: absolute;
    top: 0;
    z-index: 8;
    height: 100%;
    left: 0;
    width: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);
  }

  .background-blur.hide-header {
    z-index: 10;
  }

  .main-container {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(circle at 0% 0%,
        #393034,
        #393034,
        #050505 1065px);

    .main-content {
      height: 100%;
      width: 1110px;
    }
  }
}